iframe {
  display: none
}

.vlpointer {
  cursor: pointer;
}

.val_pad {
  padding: 3px;
  font-size: 17px;
  white-space: nowrap;
}

th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.checklist_position {
  position: relative;
  top: 9px;
}

.event_position {
  position: relative;
  top: 17px;
}

.important_record_position {
  position: relative;
  top: 17px;
}

.checklist_outerline {
  border: 1px solid #ddd;
  margin: 10px 10px 0px 10px;
  border-radius: 3px;
  padding: 5px;
}

.imp_rec{
  border-collapse: collapse;
  margin-bottom: 23px;
}

button.imp_record_dropdown_btn{
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 0px 7px;
  color: white;
  margin-left: 4px;
  margin-bottom: 5px;
  font-size:small;
  line-height:small;
}

button.imp_record_dropdown_btnp {
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 0px 5px;
  color: rgb(0, 0, 0);
  margin-left: 4px;
  margin-bottom: 5px;
  font-size:small;
  line-height:small;
}

.form_alt {
  padding: 0.2rem 0.2rem;
  margin-bottom: 0px;
  color: black;
}

.pagesetup {
  height: inherit;
  max-height: inherit;
}

.csm_btn {
  line-height: 1.4em;
  width: auto;
  border-radius: 5px;
  font-size: var(--primary-btn-size);
  font: var(--primary-font);
}

.csm_btn_pri {
  color: var(--primary-btn-color);
  background-color: var(--primary-btn-bg-color);
  border: 1px solid var(--primary-btn-bg-color);
  font-size: 13px;
  padding: 0.25rem 0.5rem;
}

.evtinputlist {
  width: 100%;
  margin: 5px 5px;
}

.fmcheck {
  margin-left: -30px;
  margin-top: 7px;
}

.checkpadd {
  width: 26px;
  height: 18px;
  border-radius: 2px;
}

.unverifi {
  border-color: red;
}

/* .formpadd {
  padding: 3px;
  line-height: inherit;
} */


.btnsrc {
  background: #fff;
  border: 1px solid #ced4da;
  border-left: 0px;
  padding: 3px 10px !important;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #000;
}

.refrcd {
  padding: 2px 5px;
  list-style-type: none;
  cursor: pointer;
  color: black;
  background: white;

}

.tableStyle {
  --bs-table-hover-color: none;
  --bs-table-hover-bg: none;
}



