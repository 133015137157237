.container-fluid {
    padding: 15px;
  }
  
  .update-records-section {
    background-color: #f8f9fa;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .update-label {
    font-weight: bold;
    font-size: 16px;
  }
  
  .form-selecttt {
 
    padding: 8px !important;
    border-radius: 4px !important;
    border: 1px solid #ced4da !important;
  }
  
  .table-header {
    background-color: #343a40;
    color: white;
  }
  
  .table-hover tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .toggle-icon {
    cursor: pointer;
    font-size: 24px;
  }
  /* Ensure the dropdown fits within the screen */
.dropdown-custom {
  width: 100%; /* Full width on small screens */
  max-width: 100%; /* Prevent overflow */
}

.dropdown-custom option {
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
}

/* Custom style for small screens */
@media (max-width: 575.98px) {
  .dropdown-custom {
    font-size: 14px; /* Adjust font size for better fit */
  }
}

  
  @media (max-width: 768px) {
    .update-label {
      font-size: 14px;
    }
  
    .toggle-icon {
      font-size: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .update-label {
      font-size: 12px;
    }
  
    .toggle-icon {
      font-size: 18px;
    }
  }
  