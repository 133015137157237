iframe {
  display: none;
}

body {
  user-select: auto; /* Disable text selection during drag */
}

.heading_top {
  font-size: 40;
  text-align: center;
  font-style: oblique;
  border: 2px #03141f;
  border-radius: 1.5px;
  justify-content: stretch;
}

.my-modal {
  width: 50vw;
  /* max-width: 40vw; */
  height: 100vh;
}

/* .modal-dialog {
  max-width: 600px;
} */

@media only screen and (max-width: 1100px) {
  .modal-dialog {
    max-width: 600px;
  }
}

@media only screen and (max-width: 500px) {
  .modal-body {
    max-height: 78vh;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 1100px) {
  .my-modal {
    width: 80vw;
    /* margin: auto; */
  }
}

@media only screen and (max-width: 500px) {
  .my-modal {
    max-width: 90vw;
    /* margin: auto; */
  }
}

.mdheight {
  height: 79vh;
}

.obj_name {
  background-color: rgb(5, 24, 42);
  color: snow;
  font-style: normal;
}


.obj_value {
  background-color: #cad0d4;
  font-style: normal;
  /* padding: 3px; */
}


/* .table_set {
padding-left: 3px;
} */
/* .main-contain {
margin-left: 5px;
} */

.dropdown-container {
  display: flex;
  flex-direction: column;
  width: 200px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
}

.menu-item {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.submenu {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: white;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  width: 200px;
}

.submenu-item {
  padding: 5px;
  cursor: pointer;
}

.submenu-item:hover {
  background-color: #e0e0e0;
}

.over {
  overflow: overlay;
}

.obj_head {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.mrg_fill {
  margin-top: 5px;
  margin-bottom: 5px;
}

.in-put {
  width: 3em;
  display: inline;
  padding: 2px 1px 1px 1px;
  font-size: 13px;
  padding-right: 1px;
  text-align: center;
  border-radius: 1px;
}

.mid {
  text-align: center;
}

.disfl {
  display: flex;
}

.bck {
  background-color: var(--primary);
  color: var(--primary-heading);
  --bs-gutter-x: 0rem;
  padding: 10px 5px;
}

.mybt {
  background: #eee;
  /* padding: 0px 5px; */
  border: 1px solid #ddd;
  border-radius: 2px;
  margin: 0px 2px;
  font-size: 14px;
  cursor: pointer;
}

.tpi {
  float: right;
}

.rcd_count {
  padding: 5px;
}

.spfont {
  font-size: 13px;
  padding: 3px;
}

.val_pad {
  padding: 3px;
  font-size: 17px;
  white-space: nowrap;
}

.val_under {
  text-decoration: underline;
}

.bt_padd {
  align-content: center;
  font-weight: bold;
}

/* .img {
width: 10%;
} */

.filter-ic {
  padding-left: 10px;
}

.bck-rel {
  border-left: 1px solid #fff;
}
.selected {
/*   background-color: #d3d3d3 */ /* Change this color to indicate row selection */
 background-color: rgba(0,0 , 0, 0.25) !important;
}

.list {
  /* position: fixed; */
  display: flex;
  display: inline;
  font: menu;
  position: relative;
}

.inp {
  align-content: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 14px;
}

.btnnn {
  /* padding: 0px 5px; */
  border: none;
  border-radius: 1px;
  margin: 0px 2px;
  font-size: 9px;
}

.addandcancel {
  margin-left: 30px;
  font-variant: all-small-caps;
  font-size: 18px;
  /* margin-left: 12px; */
}

.icupdown {
  visibility: hidden;
}

.icpadding {
  margin-left: 11px;
}

.vlpointer {
  cursor: pointer;
  white-space: nowrap;
}

.cog_pd {
  padding-left: 5px;
  padding-right: 5px;
}

.norcd {
  text-align: center;
  font-weight: bold;
  color: black;
}

.sub-btn {
  margin-left: 0.5em;
}

.csm_btn {
  line-height: 1.4em;
  width: auto;
  border-radius: 5px;
  font-size: var(--primary-btn-size);
  font: var(--primary-font);
}

.csm_btn_pri {
  color: var(--primary-btn-color);
  background-color: var(--primary-btn-bg-color);
  border: 1px solid var(--primary-btn-bg-color);
  font-size: 13px;
  padding: 0.25rem 0.5rem;
}

.srch {
  line-height: 0.8em;
  width: 30%;
  display: inline;
  padding: 0.1em 0.5em;
  margin-left: 0.5em;
}

.srch-mob {
  line-height: 0.8em;
  width: 35%;
  display: inline;
  padding: 0.1em 0.5em;
  margin-left: 0.5em;
}

.namelist-mob {
  line-height: 0.8em !important;
  width: 35% !important;
  display: inline !important;
}

.namelist {
  line-height: 0.8em !important;
  width: 30% !important;
  display: inline !important;
}

.tpp {
  padding-top: 0.5em;
}

.martop10 {
  margin-top: 10px;
}

.pdtop5 {
  padding-top: 5px;
}

/* .mod-ht {
   height: 75vh; 
   height: 75vh; 
   overflow: auto;
} */

.drop_downn {
  position: sticky;
  top: 0;
  z-index: 11;
  background-color: var(--primary);
}

.sticky-header th {
  top: -1px;
  z-index: 1;
  background-color: rgb(5, 24, 42);
}

td .btnsrcLst{
  visibility: hidden;
}

td:hover .btnsrcLst{
  visibility: visible;
/*   width: auto; */
 

}

.btnsrcLst {
  background: #fff;
  border:  solid #ced4da;
  padding:0px  1px !important; 
  border-bottom-left-radius: 0px;
   border-radius: 5px;
  color: #000;
  margin-left: 5px;
  visibility: hidden;

} 
.sticky-header_tabular th {
  top: -1px;
  z-index: 1;
  background-color: rgb(255, 255, 255);
}

.sticky-position {
  position: sticky;
  /* top: 50px; */
  /* z-index: 1;
  background-color: rgb(5, 24, 42); */
}

/* .bd_z_index_prop tr {
  z-index: 9;
} */

/* Flexbox centering */
/* .modal-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; 
} */

.tbody {
  overflow: auto;
  max-height: calc(100% - 50px);
}

.list-font-sz {
  font-size: 16.7px;
  letter-spacing: 0.6px;
}

.l-box-mar-pad {
  margin: -1px auto;
}

.tpi .form-control.in-put {
  font-size: 12px;
  padding: 4px 8px;
  margin: 2px;
}

.tpi .spfont {
  font-size: 12px;
  margin: 2px;
}
/* background-color: white;
border: 1px solid;
border-radius: 6px;

background-color: white;
border: 1px;
margin: 3px;

background-color: white;
border: 1px;
margin: 3px; */

.multiUpdateParent{
  background-color: white;
border: 1px solid;
border-radius: 6px;
align-items: center;
    justify-content: center;
/* max-width: 75%; */
}
.multiUpdateBtn1{
  background-color: white;
  border: 1px;
  margin: 3px;
  margin-left: 20px;
  margin-right: 0px;
}

.multiUpdateBtn2{
  background-color: white;
  border: 1px;
  margin: 3px;
  margin-left: 0px;
}

.inVisibleField{
  font-size: 13px;
  text-align: center;
  margin-bottom: 0px;
 /*  font-weight: bold; */
}

.pageMessage{
  padding: 0.2rem 0.2rem;
  margin-bottom: 0px;
  color: black;
  position:fixed;
  z-Index:50;
  width:70%;
  margin-left:2.5%;
  font-style: normal;
}



/************************MEDIA-QUERIES****************************/
/* @media(max-width:512px){
  .heading_top{
    overflow-x: scroll;
  }
} */

@media (max-width: 1280px) {
  .mdheight {
    height: 63vh;
  }
}

@media only screen and (max-width: 1400px) {
  .bck {
    padding: 10px 5px 10px 0px;
  }
}

@media only screen and (max-width: 768px) {
  /* .my-modal-personalized {
    max-width: 90vw;
    margin: auto;
  } */

  .filter-ic {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 !important;
    margin-bottom: 7px;
  }

  .filter-ic2 {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    padding: 0 !important;
  }

  .martop10 {
    margin-top: 0px;
  }

  .my-modal {
    width: auto;
    max-width: none;
    margin-top: 18px;
    height: auto;
    /* margin: 0.5rem; */
  }
  .pageMessage{
    width: 95%;
  }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .tpi {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .obj_head {
    font-size: 19px;
    margin-left: 13px;
  }

  .tpi .mybt {
    font-size: 12px;
    padding: 4px 8px;
    margin: 2px;
  }

  .tabView {
    margin-top: 10px;
    margin-top: 10px;
  }

  .bck {
    padding: 10px 3px 10px 0px;
  }
}