iframe {
  display: none;
}

.objname_clt {
  text-align: center;
  color: var(--primary-top-bar);
  font-style: normal;
}

.form_wdt {
  width: 100%;
}

.desc_style {
  padding-left: 10px;
  margin-top: 5px;
  font: 15px var(--primary-font);
}
@media (max-width: 500px) {
  .Table-div {
    width: 1000px;
  }
}
