iframe {
  display: none
}

.relationPage {
  background: #fff;
}

.ownNav {
  display: flex;
  flex-wrap: inherit;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.clr {
  color: #000;
}

td .btnsrcRLst{
  visibility: hidden;
}

td:hover .btnsrcRLst{
  visibility: visible;
/*   width: auto; */
 

}

.btnsrcRLst {
  background: #fff;
  border:  solid #ced4da;
  padding:0px  1px !important; 
  border-bottom-left-radius: 0px;
   border-radius: 5px;
  color: #000;
  margin-left: 5px;
  visibility: hidden;

} 
