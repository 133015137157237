.mtr_tab {
  background-color: black;
  color: white;
  margin-bottom: 7px;
}

.mtr_bdr {
  padding-top: 3rem;
  border-style: solid;
  border-width: thin;
  margin-right: 6rem;
  margin-left: 6rem;
  border-radius: 5px;
}

.mtr_bdr_mob {
  padding-top: 3rem;
  border-style: solid;
  border-width: thin;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 5px;
}

.mtr_btn {
  text-align: center;
  margin-left: 36rem;
  margin-bottom: 5px;
}

.mtr_unverifi {
  border-style: solid;
  border-color: red;
  border-radius: 2px;
}

.client_search {
  width: 412px;
  margin: 5px 16px;
  display: inline-block;
}

@media (max-width: 992px) {
  .client_search {
    width: 340px;
    margin-left: -10px;
  }
}

.mtr_client_search {
  width: inherit;
  margin: 5px 16px;
  display: inline-block;
}

.mtr_client_search_mob {
  width: 14rem;
  margin: 5px 16px;
  display: inline-block;
  padding: 0.375rem 0.75rem;
}

.mtr_clt_ref {
  border: 1px solid #ced4da;
  padding: 10px 10px;
  margin-left: -17px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.mat_check {
  width: 26px;
  height: 18px;
  border-radius: 2px;
  margin-top: 2px;
  margin-left: 2px;
}

.maincompo2 {
  background: rgb(6, 34, 66);
  color: white;
  padding: 0;
  width: 100%;
  border-radius: 0px;
  height: 100vh;
  overflow: hidden;
}


@media only screen and (max-width: 767px) {
  .mat_maincompo2 {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #002b55;
  }

  .vlpointer {
    cursor: pointer;
  }

  .mtr_clt_ref {
    position: relative;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: white; 
  }

  .clt {
    display: block;
    margin-bottom: 5px;
    font-weight: 600;
    color: white; 
  }

  .mat_check {
    margin-left: 0px;
  }

  .input-group-text {
    background: #007bff; 
    color: white;
    border: none;
    /* border-bottom-left-radius: unset;
    border-top-left-radius: unset; */
    padding: 11px 11px;
  }

  .form-control.mtr_unverifi {
    border-color: red;
  }

  .error_message {
    background-color: #ff6b6b;
    color: #fff;
    border: 1px solid #ff4757;
    border-radius: 4px;
    padding: 10px 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }

  .error_message::before {
    content: "⚠️";
    margin-right: 10px;
    font-size: 18px;
  }
}

