

.inventory_outerline {
    border: 1px solid #ddd;
    margin: 10px 10px 0px 10px;
    border-radius: 3px;
    padding: 5px;
  }

.row-color{
    background-color: #e9ecef;
  }



.srch_inv{
    width: 0;
    opacity: 0;
    transition: width 0.5s, opacity 0.5s, padding 0.5s, margin-left 0.5s;
    display: inline;
    line-height: 0.8em;
    padding: 0 0;
    margin-left: 0;
  }

  
  .srch_inv.open {
    width: 43%;
    opacity: 1;
    padding: 0.1em 0.5em;
    margin-left: 0.5em;
  }
  .tabHead{
    text-align: center;
  }

  .tab_layout {
    table-layout: fixed; 
    width: 100%; 
  }
  
  .tab_layout .itm{
    width: 250px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
  }

  .tab_layout .qnt{
    width: 180px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
  }

  .tab_layout .workshp{
    width: 250px; 
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
  }

  