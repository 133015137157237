iframe {
  display: none
}

input.inpt_typefil {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px;
  border: 1px solid;
  font-size: 13px;
}

input.inpt_typefil_ref {
  border-top-right-radius: 0px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 0px;
  padding: 5px;
  border: 1px solid;
  font-size: 13px;
}

.selct_optfil {
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  min-width: 130px;
  padding-left: 12px;
}

.mid_optfil {
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  min-width: 80px;
  padding-left: 12px;
}

.padbtnnn {
  font-size: medium;
}

.martop {
  margin-top: 5px;
}

.marg_left {
  margin-left: auto;
}

.btnsrc_fil {
  background: #fff;
  padding: 7.4px 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #000;
  /* border: 1px solid; */
  border-bottom: 1px solid;
  border-top: 1px solid;
  border-right: 1px solid;
}

.inpu_rad {
  border-radius: 4px;
  margin-top: 2px;
}

.fil_txt {
  color: var(--primary);
}

.scrl {
  overflow-x: auto;
}

.disfl {
  display: flex;
}

.clr {
  color: #161515;
}

.fl1 {
  flex: 1;
}

.fl2 {
  flex: 2;
}

.wd25 {
  width: 25%;
}

.wd50 {
  width: 50%;
}

.pdrt10 {
  padding-right: 10px;
}

.pdlf10 {
  padding-left: 10px;
}

.pdtp5 {
  padding-top: 5px;
}

.fntSz15 {
  font-size: 15px;
}

.cancelbtn {
  font-size: 1.2em;
}

.ref-rcd {
  padding: 2px 5px;
  list-style-type: none;
  cursor: pointer;
  color: black;
  background: white; /*#ffe */
  border-radius: 3px;
  width: 200px;
  overflow-x: scroll;
  position: absolute;
  border: 1px solid #ddd;
}