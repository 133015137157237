iframe {
  display: none
}

.otpinp {
  width: 45px;
  height: 44px;
  border: 2px solid white;
  border-radius: 6px;
  margin-left: 19px;
  text-align: center;
}

.cardchild {
  margin-top: -13px;
  /* padding-top: 60px; */
  padding-top: 1px;
}

.usericpadd {
  display: inline-flex;
  justify-content: center
}

.fp_input {
  width: 15%;
}

.alertgp {
  position: relative;
  padding: 0.5rem 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
@media only screen and (max-width:500px){
  .ctd{
 width: 80%;
  }
}




