/* @import url("https://fonts.googleapis.com/css?family=Numans");

html,
body {
  background-image: url("http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  font-family: "Numans", sans-serif;
} */

iframe {
  display: none;
}

.ftsize {
  font-size: 32px !important;
}

.cen {
  text-align: center;
}

.pad5 {
  padding: 5px;
}

.btnyellow {
  line-height: 1em;
  background-color: #ffc312;
}

.pos {
  position: relative;
  top: 5px;
}

.cen_set {
  /* width: 70%; */
  width: 100%;
  margin: auto;
}

.cen_set-screen {
  /* width: 70%; */
  width: 70%;
  margin: auto;
}

.card_log_mar {
  margin: 60px;
}

/* .card_mrg_mob {
  margin-top: 100px;
} */

.card_log {
  /* margin-bottom: auto; */
  width: 450px;
  background-color: rgba(0, 0, 0, 0.5) !important;
}
@media (min-width: 1500px) {
  .card_log {
    /* margin-bottom: auto; */
    width: 450px;
    background-color: rgba(0, 0, 0, 0.5) !important;
  }
}

@media only screen and (max-width: 1200px) {
  .card_log {
    margin: 29px;
    width: 380px;
  }

  @media only screen and (max-width: 485px) {
    .card_log {
      width: 94%;
      margin: 0;
      background-color: rgba(0, 0, 0, 0.5) !important;
    }
  }
}
.card_log2 {
  background-color: rgba(0, 0, 0, 0.5) !important;
  width: 93%;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #0a47f0;
}

.bdr_btm{
  border-bottom: 1px solid #dddfe2;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.fotr_prop{
  font-size: 15px;
  text-align: center;
  color: #385898;
}

.underline {
  cursor: pointer;
  text-decoration: none;
}

.underline:hover {
  text-decoration: underline;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
  font-size: 25px;
  text-align: center;
  padding: 6px;
  /* background: darkgray; */
  border-radius: 7px;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #ffc312;
  color: black;
  border: 0 !important;
}

.lin {
  padding: 3px;
}

.lnk-color {
  color: #ffc312;
}

.lnk-color:hover {
  color: white;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
  margin-top: 1em;
  text-align: center;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #ffc312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
  color: #ccffff;
}

.hidepassword {
  margin-left: -43px;
  margin-top: 8px;
}

.inpptt {
  border-radius: 22px !important;
  width: 310px;
  height: 34px;
  padding: 14px;
  border: 2px;
}

.carrd {
  width: 30%;
  /* height: 508px; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: gray;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

@media only screen and (max-width: 1100px) {
  .carrd {
    width: 50%;
  }
}
@media only screen and (max-width: 900px) {
  .carrd {
    width: 60%;
  }
}
@media only screen and (max-width: 500px) {
  .carrd {
    width: 95%;
  }
}

.otpCard {
  width: 23%;
  /* height: 508px; */
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: gray;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

@media only screen and (max-width: 1100px) {
  .otpCard {
    width: 40%;
  }
}
@media only screen and (max-width: 900px) {
  .otpCard {
    width: 50%;
  }
}
@media only screen and (max-width: 600px) {
  .otpCard {
    width: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .otpCard {
    width: 95%;
  }
}

.login_container {
  display: flex;
  height: 90vh;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
}

.mobile-login-button-posi {
  position: relative;
  top: 12px;
}

.forgot-password-position {
  /* style={{ left : "104px" }} */
  position: relative;
  left: 104px;
  top: 4px;
}

.bdr-rdus {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.reg-message {
  width: 90%;
  margin: auto;
  align-items: center;
  text-align: center;
  /* margin-top: 12px; */
  /* margin-bottom: -150px; */
}
@media (min-width: 512px) {
  .reg-message {
    margin-top: 12px;
    width: 60%;
  }
}
.tick {
  color: green;
  margin-right: 5px;
}

.cross {
  color: red;
  margin-right: 5px;
}
.setinp {
  border-radius: 22px;
  width: 80%;
  height: 34px;
  padding: 14px;
  border: 2px;
}
