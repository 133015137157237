iframe {
  display: none;
}

.box-pref {
  border: 1px solid black;
  border-radius: 3%;
  padding: 10px;
  /* box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297); */
  margin-inline: 5px;
  height: 214px; /*New Property added*/
  /* max-height: 500px; Previous Property.*/
  max-height: 214px;
  overflow: auto;
}

/* .up-down {

} */
.up-down-view-pref2 {
  display: flex;
  width: fit-content !important;
  flex-direction: column;
  justify-content: center;

/*   transform: rotate(-270deg); */
  text-align: center;
  font-family: var(--primary-font);
  margin: -15px 0px;

}


.up_down_btn_pre {
  display: flex;
  flex-direction: row;
}

.selected-row {
  background-color: var(--bs-primary) !important;
  color: #fff !important;
}

.up-down-view-pref {
  margin-left: 3px;
  transform: rotate(-90deg);
  text-align: center;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  font-family: var(--primary-font);
  padding: 0px 8px;

}
.up-down-view-pref3 {
  margin-left: 3px;
  transform: rotate(-90deg);
  text-align: center;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  font-family: var(--primary-font);
  padding: 0px 8px;
  margin-right: 10px;

}

/* .btn-group-vertical {
  bottom: 27px;
} */

.submit_butn_of_setting {
  font-size: 11px;
  border-radius: 4px;
}

.ryt-left-btn {
  margin-left: 3px;
  text-align: center;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  font-family: var(--primary-font);
  padding: 0px 8px;
  margin-bottom: 2px;
}
