/* @import url("https://fonts.googleapis.com/css?family=Numans");

html,
body {
  background-image: url("http://getwallpapers.com/wallpaper/full/a/5/d/544750.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  font-family: "Numans", sans-serif;
} */

iframe {
  display: none;
}

.inpp {
  border-radius: 22px;
  width: 310px;
  height: 34px;
  padding: 14px;
  border: 2px;
}

.ftsize {
  font-size: 32px;
}

.usericpadd {
  display: inline-flex;
  /* justify-content: center;
    flex-wrap: nowrap; */
}

.iconnnnn {
  font-size: 22px;
  margin-top: 8px;
  margin-left: -13px;
}

.cardddd {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: gray;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.card-body {
  text-align: center;
}

.card-footer {
  text-align: center;
  /* padding-top: 50px; */
  font-size: 19px;
}

.cardchild {
  /* padding-top: -11px; */
  margin-top: -13px;
  padding-top: 25px;
}

.inp-grp {
  width: 260px;
  height: 25px;
  border: 2px solid white;
  border-radius: 15px;
  margin: 3px;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #0a47f0;
}

.social_icon span:hover {
  color: white;
  cursor: pointer;
}

.card-header h3 {
  color: white;
  font-size: 25px;
  text-align: center;
  padding: 6px;
  /* background: darkgray; */
  border-radius: 7px;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

/* .input-group-prepend span {
	width: 50px;
	background-color: #ffc312;
	color: black;
	border: 0 !important;
} */

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;
}

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

/* .login_btn {
	color: black;
	background-color: #ffc312;
	width: 100px;
}

.login_btn:hover {
	color: black;
	background-color: white;
} */

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}

.checkBox {
  margin-left: 45px;
  margin-top: 7px;
  font-size: 18px;
}

.btn-hover {
  width: 135px;
  height: 31px;
  /* border: 2px solid white; */
  border-radius: 14px;
  margin: 4px;
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .cardddd {
    /* margin: 61px !important; */
    margin-top: 53px !important;
  }
  .panel_width {
    max-width: 431px;
  }
}
