iframe {
  display: none
}

.sort_table {
  border: 0.6px solid #ddd;
  /* border-radius: 10px; */

  /* background-color: rgb(202, 255, 251); */
}

.cen {
  text-align: center;
}

.tb_wid {
  min-width: 5%;
}

.obj-name {
  text-align: center;
  color: cornsilk;
  background-color: rgb(5, 24, 42);
}

.row-padd {
  text-align: center;
  justify-content: stretch;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  flex-direction: row;
  display: inline;
}

.up_tab {
  width: 98%;
  margin: auto;
  overflow: overlay;
}

.over {
  overflow: overlay;
}

.wtsp {
  white-space: nowrap;
  /* background-color: rgb(144, 148, 151); */
}

.loadbtn {
  margin: 24px;
  cursor: pointer;
}

.mrg-left{
  margin-left: 1em;
}

/* .ovly {
	overflow: overlay;
} */
