iframe {
  display: none
}

.heading{
  text-align: center;
  font-weight: bold;
  font-size: large;
}

.sub_hed{
  background-color: black;
  color: white;
  padding-left: 13em;
  text-align: center;
}

.bttn{
  padding-left: 30em;
  margin-top: 2px;
  margin-bottom: 2px;
}

.rddd{
  border-radius: 10px;
}

.rpt_border{
  border: 1px solid;
}

.tdddd{
  padding-left: 8px;
  font-weight: bold;
}

.tddd1{
  padding-left: 25px;
}
.selct_optfil {
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  min-width: 130px;
  padding-left: 12px;
}
.pdrt10 {
  padding-right: 10px;
  text-wrap: nowrap;
}
.pdlf10 {
  padding-left: 10px;
}

.pdtp5 {
  padding-top: 5px;
}

input.inpt_typefil {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 5px;
  border: 1px solid;
  font-size: 13px;
}

.inpu_rad {
  border-radius: 4px;
  margin-top: 2px;


}
.fntSz15 {
  font-size: 15px;
}
.disfl {
  display: flex;
}

.css-13cymwt-control{
  border-color: black !important;
  margin-bottom: 3px !important;
}
