iframe {
  display: none
}

.vlpointer {
  cursor: pointer;
}

.val_pad {
  padding: 3px;
  font-size: 17px;
  white-space: nowrap;
}

.val_under {
  text-decoration: underline;
}


th {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

/* thead, tbody, tfoot, tr, td, th{
  position: relative;
  bottom: 5px;
} */

.checklist_position {
  position: relative;
  top: 9px;
}

.event_position {
  position: relative;
    top: 17px;
}

.important_record_position {
  position: relative;
  top: 17px;
}

.checklist_outerline {
  border: 1px solid #ddd;
  margin: 10px 10px 0px 10px;
  border-radius: 3px;
  padding: 5px;
}

.imp_rec{
  border-collapse: collapse;
  margin-bottom: 23px;
}

/* button.save_btn{
    position: relative;
    margin-left: 459%;
} */

button.imp_record_dropdown_btn{
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 0px 7px;
  color: white;
  margin-left: 4px;
  margin-bottom: 5px;
  font-size:small;
  line-height:small;
}

button.imp_record_dropdown_btnp {
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 0px 5px;
  color: rgb(0, 0, 0);
  margin-left: 4px;
  margin-bottom: 5px;
  font-size:small;
  line-height:small;
}

.custom-card {
  border: 1px solid #ddd;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.custom-card-header {
  background-color: #f8f9fa;
  padding: 10px;
  cursor: pointer;
}

.custom-card-toggle-btn,
.custom-card-toggle-btnp {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.custom-card-body {
  padding: 10px;
}

.custom-table {
  width: 100%;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.custom-table thead th {
  background-color: #f8f9fa;
}

.custom-table tbody tr:hover {
  background-color: #f1f1f1;
}

.custom-val-pointer {
  cursor: pointer;
}

.font-weight-bold {
  font-weight: bold;
}





.checklist_outerline {
  margin: 20px auto;
  padding: 20px;
  max-width: 100%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.check-card {
  overflow-y: auto; /* Allow vertical scrolling */
  max-height: 400px; /* Set a max height for the table container */
}

.check-header {
  /* position: sticky;
  top: 0; */
  background-color: #ffffff; /* Ensure the header has a background color */
  /* z-index: 2; */
  margin-bottom: 0px;
  padding: 0px 10px
}


.check-table-header th {
  position: sticky;
  top: 0;
  background-color: #ffffff; /* Ensure the header has a background color */
  /* z-index: 2; */
 
}


.evtinputlist {
  width: 100%;
/*   margin: 5px 5px; */
padding: 5px;
}

.check-table-header,
.check-table-row {
    padding: 15px 10px; /* Increase padding to increase row height */
    height: 50px; /* Optional: Set a specific height if you prefer */
}
.check-table-header th,
.check-table-row td {
    vertical-align: middle; /* Ensure content is centered vertically */
}

.check-title {
  font-size: 17px;
  text-align: center;
  flex-grow: 1;
}

.check-save-btn {
  margin-left: auto;
  flex-shrink: 0;
}

.check-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffffff;
}

.check-tbody {
  font-size: 17px;
}

.check-table-header {
  background-color: #e9ecef;
}

.check-table-row {
  background-color: #fff;
}

.check-no-record {
  text-align: center;
  color: #888;
  font-style: italic;
}

@media (max-width: 768px) {
/*   .check-header {
      flex-direction: column;
      text-align: center;
  } */

  .check-save-btn {
      margin-top: 10px;
     /*  width: 100%; */
  }

  .check-title {
      font-size: 1.25rem;
  }

}

@media (max-width: 576px) {


}

/* thead.important_records{
  border-bottom: 1px solid black;
} */
/* For medium screens (e.g., tablets) */
@media (min-width: 768px) {
  .check-card {
      max-height: 400px; /* Adjust as needed for tablets */
  }
  .evtinputlist {
    width: 70%;
  
  }
}

/* For large screens (e.g., laptops) */
@media (min-width: 992px) {
  .check-card {
      max-height: 500px; /* Adjust as needed for laptops */
  }
}

/* For extra-large screens (e.g., desktops) */
@media (min-width: 1200px) {
  .check-card {
      max-height: 600px; /* Adjust as needed for desktops */
  }
}


