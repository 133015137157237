.acn-del {
  width: 500px;
  height: fit-content !important;
  border-radius: 5px !important;
  border: 1px solid black !important;
  border-radius: 8px;
}

@media screen and (max-width: 768px) {
  .acn-del {
    width: 95%;
  }
}
.card-header-d {
  background-color: #f4efefb0;
  display: flex;
  justify-content: space-around;
  padding: 7px 0px;
}
.d-input {
  border-radius: 5px;
  width: 100%;
  padding: 14px;
  background-color: #fff;
  border: 1px solid #f4f4f4 !important;
  height: 44px;
}
.form-signin {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 15px;
  text-align: left;
  width: 100%;
}
