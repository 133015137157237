.custom-card {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: #fff;
  }
  
  .custom-card-header {
    background-color: #f8f9fa;
    padding: 10px;
    /* cursor: pointer; */
  }
  
  .custom-card-toggle-btn,
  .custom-card-toggle-btnp {
    background: none;
    border: none;
    font-size: 18px;
    /* cursor: pointer; */
  }
  
  .custom-card-body {
    padding: 10px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
    text-align: center;
    table-layout: fixed;
    word-wrap: break-word;
  }
  
  .custom-table th,
  .custom-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
    text-align: center; 
    vertical-align: middle;
  }
  
  .custom-table thead th {
    background-color: #f8f9fa;
  }
  
  .custom-table tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  .custom-val-pointer {
    cursor: pointer;
  }

  .underline {
    cursor: pointer;
    text-decoration: none;
  }
  
  .underline:hover {
    text-decoration: underline;
  }
  
  .font-weight-bold {
    font-weight: bold;
  }

  .head_btn{
    cursor: pointer;
    color: white;
    margin-right: auto;
    width: 4rem;
    font-weight: bold;
  }

  /* Expense Section Specific Styles */
.expense-section-card {
    margin: 15px 0;
    border: 1px solid #dee2e6;
    padding: 10px;
    border-radius: 5px;
    background-color: #fff;
  }
  
  .expense-header {
    background-color: #f8f9fa;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
    border-radius: 5px 5px 0 0;
  }
  
  .exp_record_dropdown_btn,
  .exp_record_dropdown_btnp {
    background-color: #6c757d;
    border: none;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .exp_record_dropdown_btnp {
    background-color: #007bff;
  }
  
  .expense-table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
  }
  
  .expense-table-header {
    background-color: #e9ecef;
  }
  
  .expense-table th,
  .expense-table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #dee2e6;
  }
  
  .expense-table-row:hover {
    background-color: #f1f1f1;
  }
  
  .expense-total-row td {
    font-weight: bold;
    background-color: #f8f9fa;
  }
  
  .exp_val_pad {
    padding: 5px;
  }
  
  .exp_val_under {
    text-decoration: underline;
  }
  
  .exp_vlpointer {
    cursor: pointer;
  }
  