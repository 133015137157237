/* iframe {
    display: none
} */

/* .inventory_table {
    display: flex;
}

.inventory_column {
    margin-bottom: 1em;
    width: 100%;
}

.inv_row {
    border: 1px black solid;
    height: 3em;
    margin: auto;
    width: 100%;
} */

.inventory_outerline {
    border: 1px solid #ddd;
    margin: 10px 10px 0px 10px;
    border-radius: 3px;
    padding: 5px;
  }

.row-color{
    background-color: #e9ecef;
  }

  /* Add this CSS code to your stylesheet */

.srch-inv {
    width: 0;
    opacity: 0;
    transition: width 0.5s, opacity 0.5s, padding 0.5s, margin-left 0.5s;
    display: inline;
    line-height: 0.8em;
    padding: 0 0;
    margin-left: 0;
  }

  /* .srch {
    width: 30%;
} */
  
  .srch-inv.open {
    width: 30%;
    opacity: 1;
    padding: 0.1em 0.5em;
    margin-left: 0.5em;
  }
  .tabHead{
    text-align: center;
  }
  