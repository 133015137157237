.mtr_tab {
  background-color: black;
  color: white;
  margin-bottom: 7px;
}

.mtr_bdr {
  padding-top: 3rem;
  border-style: solid;
  border-width: thin;
  margin-right: 6rem;
  margin-left: 6rem;
  border-radius: 5px;
}

.mtr_bdr_mob {
  padding-top: 3rem;
  border-style: solid;
  border-width: thin;
  margin-right: 2rem;
  margin-left: 2rem;
  border-radius: 5px;
}

.mtr_btn {
  text-align: center;
  margin-left: 36rem;
  margin-bottom: 5px;
}

.mtr_unverifi {
  border-style: solid;
  border-color: red;
  border-radius: 2px;
}

.client_search {
  width: 412px;
  margin: 5px 16px;
  display: inline-block;
}

@media (max-width: 992px) {
  .client_search {
    width: 340px;
    margin-left: -10px;
  }
}

.mtr_client_search {
  width: inherit;
  margin: 5px 16px;
  display: inline-block;
}

.mtr_client_search_mob {
  width: 14rem;
  margin: 5px 16px;
  display: inline-block;
  padding: 0.375rem 0.75rem;
}

.mtr_clt_ref {
  border: 1px solid #ced4da;
  padding: 10px 10px;
  margin-left: -17px;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.mat_check {
  width: 26px;
  height: 18px;
  border-radius: 2px;
  margin-top: 2px;
  margin-left: 2px;
}

/* **************Mobile View********************** */

@media only screen and (max-width: 767px) {
  .vlpointer {
    cursor: pointer;
  }

  .mtr_clt_ref {
    position: relative;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: white; /* Ensures the icon is visible on the dark background */
  }

  .clt {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: white; /* Assuming the background is dark, for better readability */
  }

  .mat_check {
    margin-left: 0px;
    width: 18px;
  }

  .input-group-text {
    background: #007bff; 
    color: white;
    border: none;
    /* border-bottom-left-radius: unset;
    border-top-left-radius: unset; */
    padding: 11px 11px;
  }

  .form-control.mtr_unverifi {
    border-color: red;
  }

  .set-material-records-heading {
    background: linear-gradient(to right, #1a2a3a, #2a3a4a);
    padding: 15px 20px;
    /* border-radius: 8px; */
    border-radius: 0px 0px 8px 8px;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .set-material-records-heading h1 {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
    position: relative;
  }
  
  .set-material-records-heading h1::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 50px;
    height: 3px;
    background-color: #007bff;
    border-radius: 3px;
  }
}
