iframe {
  display: none
}

.prefinputlis {
  width: 412px;
}

@media(max-width:512px){
  .prefinputlis {
    width: 100%;
  }
}

.evtinputlis {
  width: 300px;
  margin: 5px 5px;
}

.inpt_typefil {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 5px;
  border: 1px solid;
  font-size: 16px;
}

.mlt_select {
  width: 100%;
  height: 300px;
}

.up_down_btn {
  cursor: pointer;
  font-size: 30px;
  padding-top: 100px;
}

.dis-flex {
  display: flex;
}

.prf_bdr {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
