iframe {
  display: none
}

.maincompo_blk {
    padding: 2%;
    background: #fff; /* rgb(6, 34, 66); */
    color: black;
    /* width: 50%; */
    align-items: center;
  }

  .client-details-box {
    padding: 9px;
    margin-bottom: 0px;
  }

  .inst-btn {
    padding-right: 5px;
  }

  /* .highlight {
    border: 2px solid blue;
  } */

  .var-blank-compo {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f7fa;
    /* border-radius: 8px; */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
  }

  .form-container {
    margin-bottom: 20px;
  }

  .form-container label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
  }